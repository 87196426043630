import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { css } from 'styled-components/macro';
import SEO from '../components/SEO';
import { Link } from 'gatsby';
import {
  Article,
  Main,
  Container,
  LinkBtnTransparent,
} from '../components/Elements';
import { Box, Heading, Text, Flex } from 'rebass';
import Img from 'gatsby-image';
import Header from '../components/Header';
import FooterPre from '../components/FooterPre';
const BlogPost = ({ data }) => {
  const seo = {
    tags: [
      {
        tagName: 'title',
        content: 'Tietosuojaseloste',
      },
    ],
  };

  return (
    <Fragment>
      <SEO meta={seo} />
      <div className="hamburgerColorBlue">
        <Header logo="white" />
      </div>

      <Main
        bg={'peachBg'}
        pt={7}
        css={`
          padding-top: 60px !important;
          section:first-child {
            height: 700px;
          }
          article {
            position: relative;
            margin-top: 0;
            z-index: 100;
          }
          @media (max-width: 1080px) {
            section:first-child {
              height: 500px;
            }
            article {
              margin-top: 0;
            }
          }
          @media (max-width: 1080px) {
            section:first-child {
              height: 50vh;
            }
            article {
              margin-top: 0;
            }
          }
          h1 {
            font-family: 'utopia-std', serif;
            text-transform: uppercase;
            letter-spacing: 1.4px;
            font-size: 42px;
            line-height: 1.34;
            font-weight: 600;
          }
          @media (max-width: 600px) {
            h1 {
              font-size: 22px;
            }
          }
        `}
      >
        <Article
          as="article"
          bg="#fff"
          color="darkBlue"
          maxWidth={1088}
          mx={'auto'}
          my={[1, '80px !important']}
        >
          <Box
            maxHeight="580px"
            overflow="hidden"
            mx="auto"
            maxWidth="980px"
          ></Box>
          <Box maxWidth="720px" mx="auto" my={3}>
            <div
              dangerouslySetInnerHTML={{
                __html: data.datoCmsContact.tietosuojaseloste,
              }}
            />
          </Box>
        </Article>
      </Main>
      <FooterPre />
    </Fragment>
  );
};

export const projectQuery = graphql`
  query TietosuojaQuery {
    datoCmsContact {
      tietosuojaseloste
    }
  }
`;

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BlogPost;
